import { PublicClientApplication} from "@azure/msal-browser";
import { msalConfig } from "@src/msalConfig";

const sessionIdKey = "postal-session-id"
const isBrowser = typeof window !== "undefined"

export const setToken = (token: string): void => {
  isBrowser && localStorage.setItem(sessionIdKey, token)
}

export const getToken = (): string | null => {
  return isBrowser ? localStorage.getItem(sessionIdKey) : null
}

export const setJwtToken = (token: string): void => {
  isBrowser && localStorage.setItem("jwtToken", token)
}

export const removeJwtToken = (): void => {
  isBrowser && localStorage.removeItem("jwtToken")
}

export const getJwtToken = (): string | null => {
  return isBrowser ? localStorage.getItem("jwtToken") : null
}

export const logout = async (): Promise<void> => {
  isBrowser && localStorage.removeItem(sessionIdKey)
  isBrowser && localStorage.removeItem("jwtToken");
  const instance = new PublicClientApplication(msalConfig);
  const logoutRequest = {
    account: instance.getActiveAccount()
  }
  await instance.logoutRedirect(logoutRequest);
}
